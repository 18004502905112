<template>
  <div>
    <v-tabs v-model="tab" background-color="secondary" centered>
      <v-tab v-for="item in items" :key="item.title">
        <v-icon v-text="item.icon" left></v-icon>
        {{ item.title }}
      </v-tab>
    </v-tabs>
    
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.title">
        <v-card flat>
          <v-card-text class="black--text">
            <component :is="item.component" @update-tab="tab = $event"></component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PrivacyPolicy from '@/components/legal/PrivacyPolicy.vue';
import CookiePolicy from '@/components/legal/CookiePolicy.vue';
import LegalDisclaimer from '@/components/legal/LegalDisclaimer.vue';

export default {
  name: 'Legal',
  components: {
    PrivacyPolicy,
    CookiePolicy,
    LegalDisclaimer
  },
  data() {
    return {
      tab: null,
      items: [
        {
          title: 'Legal disclaimer',
          icon: 'mdi-tie',
          component: LegalDisclaimer
        },
        {
          title: 'Privacy policy',
          icon: 'mdi-eye-off',
          component: PrivacyPolicy
        },
        {
          title: 'Cookie policy',
          icon: 'mdi-cookie',
          component: CookiePolicy
        }
      ]
    }
  }
}
</script>